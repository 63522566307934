import { WindowLocation } from '@reach/router';
import { Blok } from '../../interfaces/storyblok/Blok.interface';
import { StoryblokEntry } from '../../interfaces/storyblok/StoryblokEntry.interface';
import { isEditMode } from '../../utils/isEditMode/isEditMode';
import renderBloks from '../../utils/renderBlocks/renderBloks';
import useStoryblok from '../useStoryblok/useStoryblok.hook';

const getContent = (
  storyblokEntry: StoryblokEntry,
  location: WindowLocation
): Blok[] => {
  if (isEditMode()) {
    const {
      content: { body },
    } = useStoryblok(storyblokEntry, location) as any; // TODO: Type this correctly as 'content' can be either a string or JSON

    return body;
  }

  const { body } = JSON.parse(storyblokEntry.content);

  return body;
};

const useDynamicComponent = (
  storyblokEntry: StoryblokEntry,
  location: WindowLocation
): JSX.Element[] => {
  const content = getContent(storyblokEntry, location);
  return renderBloks(content);
};

export default useDynamicComponent;

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/utils/Layout/Layout.component';
import Meta from '../components/utils/Meta/Meta.util';
import RegionGuard from '../components/utils/RegionGuard/RegionGuard.util';
import { AuthContext } from '../contexts/Auth.context';
import useDynamicComponent from '../hooks/useDynamicComponent/useDynamicComponent.hook';
import { StoryblokEntry } from '../interfaces/storyblok/StoryblokEntry.interface';
import getMetaData from '../utils/getMeta/GetMeta.util';
import BreadcrumbProps from '../interfaces/Breadcrumb/Breadcrumb.interface';
import getRequiredHeaderBlok from '../utils/getRequiredHeaderBlok/getRequiredHeaderBlok.util';
import getRequiredFooterBlok from '../utils/getRequiredFooterBlok/getRequiredFooterBlok.util';
import getUiLocales from '../utils/getUiLocales/getUiLocales.util';
import DynamicComponent from '../components/utils/DynamicComponent/DynamicComponent.component';

interface PrivateStoryblokEntryDataProps {
  storyblokEntry: StoryblokEntry;
}

interface PageContext {
  breadcrumb: BreadcrumbProps;
}

const PrivateStoryblokEntryTemplate: React.FC<
  PageProps<PrivateStoryblokEntryDataProps, PageContext>
> = ({ location, pageContext }) => {
  const { user } = useAuth0();

  console.log('pageContext: ', pageContext);

  const { breadcrumb, content, full_slug, id, name, alternates } =
    pageContext as any;

  const blocks = useDynamicComponent(
    {
      content,
      full_slug,
      id,
      name,
      alternates,
    },
    location
  );

  const CANONICAL_URL = JSON.parse(content)?.canonical_url || '';
  const NO_INDEX = JSON.parse(content)?.no_index || '';

  const metaData = getMetaData(content);
  const FULL_SLUG = full_slug;
  const URL_PARTS = FULL_SLUG.split('/');

  const ALTERNATIVE_VERSIONS = alternates;
  const LANG_PREFIX = (URL_PARTS?.length >= 1 && URL_PARTS[0]) || '';

  const PAGE_SLUG = FULL_SLUG.replace(`${LANG_PREFIX}/`, '').replace(
    `${process.env.DOMAIN_FOLDER}`,
    ''
  );
  const isAlternativeNav = PAGE_SLUG === '/';

  const headerBlok = getRequiredHeaderBlok('member_header', LANG_PREFIX);
  const footerBlok = getRequiredFooterBlok('member_footer', LANG_PREFIX);

  const HEADER = headerBlok && (
    <DynamicComponent blok={JSON.parse(headerBlok.content)} />
  );
  const FOOTER = footerBlok && (
    <DynamicComponent blok={JSON.parse(footerBlok.content)} />
  );

  return (
    <AuthContext.Provider value={{ user }}>
      <RegionGuard currentLang={LANG_PREFIX}>
        <Layout
          langPrefix={LANG_PREFIX}
          isAlternativeNav={isAlternativeNav}
          breadcrumb={breadcrumb}
          header={HEADER}
          footer={FOOTER}
        >
          {metaData && (
            <Meta
              metaData={metaData}
              url={location.href}
              canonical={CANONICAL_URL}
              noIndex={NO_INDEX}
              alternatives={ALTERNATIVE_VERSIONS}
              breadcrumb={pageContext.breadcrumb}
              pageSlug={PAGE_SLUG}
              langPrefix={LANG_PREFIX}
            />
          )}
          {blocks}
        </Layout>
      </RegionGuard>
    </AuthContext.Provider>
  );
};

export default withAuthenticationRequired(PrivateStoryblokEntryTemplate, {
  loginOptions: { ui_locales: getUiLocales() },
});
